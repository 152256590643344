// Variable overrides first
$primary: #900;
$enable-shadows: true;
$prefix: "mo-";

// Then import Bootstrap
@import "../node_modules/bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css2?family=Tektur:wght@300;400;500;600;700;800;900&display=swap');

$black: #000000;
$cornflower-lilac: #ffaea9;
$salmon: #ff7b73;
$white: #ffffff;
$your-pink: #ffcccc;

/* You can add global styles to this file, and also import other style files */
body {
    background-color: #0b0b0b;
    padding: 0;
    margin: 0;
    /*background-image: url('/assets/images/body-bg.jpg');
    background-repeat: repeat;
    background-size: auto;*/
}

.letra-robot {
    font-family: 'Tektur', sans-serif;
}

.letra-color-lumen {
    color: #ffbd31;
}

.letra-color-negro {
    color: #000000;
}

.modulo {
    width: 100vw;
    max-width: 100vw;
    min-height: 100vh;
    overflow: hidden;
    background-image: url('/assets/images/body-bg.jpg');
}

.lista-seleccionable {
    padding: 10px;
    box-sizing: border-box;
    box-shadow: 0 0.125em 0.3125em rgba(0, 0, 0, 0.3);
    display: block;
    border-radius: 5px;
    background-color: #ffffff;
    font-weight: 500;
    // margin-left: 20px;
    // margin-right: 20px;
    font-family: 'Tektur', sans-serif;
    font-size: 20px;
    width: 100%;
    overflow: hidden;
    overflow-x: auto;

    .item-lista {
        width: 100%;
        padding: 10px;
        
        &:hover {
            cursor: pointer;
            background-color: #ffbd31f1;
        }
    }
    
}

.ficha {
    padding: 10px;
    box-sizing: border-box;
    box-shadow: 0 0.125em 0.3125em rgba(0, 0, 0, 0.3);
    display: block;
    border-radius: 5px;
    background-color: #ffffff;
    font-weight: 500;
    // margin-left: 20px;
    // margin-right: 20px;
    font-family: 'Tektur', sans-serif;
    font-size: 20px;
    width: 100%;
    overflow: hidden;
    overflow-x: auto;
    
    &:hover {
        cursor: pointer;
        background-color: #ffbd31f1;
    }    
}

.ficha-seleccionada {
    padding: 10px;
    box-sizing: border-box;
    box-shadow: 0 0.125em 0.3125em rgba(0, 0, 0, 0.3);
    display: block;
    border-radius: 5px;
    background-color: coral;
    font-weight: 500;
    // margin-left: 20px;
    // margin-right: 20px;
    font-family: 'Tektur', sans-serif;
    font-size: 20px;
    width: 100%;
    overflow: hidden;
    overflow-x: auto;
}